import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import config from "../config";
import axios from "axios";

const Bookings = () => {
  const currentDate = new Date();
  const ninetyDaysAgo = new Date(currentDate);
  ninetyDaysAgo.setDate(currentDate.getDate() - 90);

  const [bookings, setBookings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [filterOption, setFilterOption] = useState("");
  const [fromDate, setFromDate] = useState(
    ninetyDaysAgo.toISOString().split("T")[0]
  ); // Set to 90 days ago
  const [toDate, setToDate] = useState(currentDate.toISOString().split("T")[0]); // Set to current date
  const [statusFilter, setStatusFilter] = useState("");
  const [totalBookings, setTotalBookings] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  // Initial data fetch when the component is first rendered
  useEffect(() => {
    fetchBookings(1); 
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line

  const fetchBookings = async (page = 1) => {
    if (!fromDate || !toDate) return;

    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/api/book/bookings`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          params: {
            page,
            limit: 10,
            ...(searchText && filterOption && { searchText, filterOption }),
            ...(statusFilter && { statusFilter }),
            fromDate,
            toDate,
          },
        }
      );

      setBookings(response.data.bookings);
      setTotalBookings(response.data.totalCount);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };

  const handleSearchSubmit = () => {
    setCurrentPage(1); // Reset to the first page when a new search is performed
    fetchBookings(1); // Only fetch when Search is clicked
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchBookings(pageNumber);
  };

  return (
    <div className="flex min-h-screen bg-gray-800 text-white">
      <Sidebar />
      <div className="p-6 m-5 min-h-screen flex-1">
        <h1 className="text-3xl font-bold mb-6">All Bookings</h1>
        <div className="flex flex-col mb-6">
          <div className="flex items-center mb-4">
            <label className="mr-4 text-lg">Filter By:</label>
            <select
              value={filterOption}
              onChange={(e) => setFilterOption(e.target.value)}
              className="mr-4 p-2 rounded text-black"
            >
              <option value="">Select Filter</option>
              <option value="customerName">Customer Name</option>
              <option value="orderId">Order ID</option>
              <option value="visa">Country Visa</option>
            </select>
            <input
              type="text"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Enter search text"
              className="mr-4 p-2 rounded text-black"
            />
            <select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              className="mr-4 p-2 rounded text-black"
            >
              <option value="">All Status</option>
              <option value="processing">processing</option>
              <option value="Payment Due">Payment Due</option>
              <option value="cancelled">Cancelled</option>
              <option value="Review">Review</option>
              <option value="Completed">Completed</option>
            </select>
            <input
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              className="mr-4 p-2 rounded text-black"
            />
            <input
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              className="mr-4 p-2 rounded text-black"
            />
            <button
              onClick={handleSearchSubmit}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Search
            </button>
          </div>
        </div>
        <table className="min-w-full bg-white text-black">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">BookingID</th>
              <th className="py-2 px-4 border-b">OrderID</th>
              <th className="py-2 px-4 border-b">Customer Name</th>
              <th className="py-2 px-4 border-b">Visa Type</th>
              <th className="py-2 px-4 border-b">Status</th>
              <th className="py-2 px-4 border-b">Result</th>
              <th className="py-2 px-4 border-b">Date</th>
              <th className="py-2 px-4 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {bookings.map((booking) => (
              <tr key={booking.bookid}>
                <td className="py-2 px-4 border-b text-center">
                  {booking.bookid}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  {booking.bid}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  {booking.customer_name}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  {booking.visatype}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  {booking.status}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  {booking.review}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  {new Date(booking.createdon).toLocaleDateString()}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  <a href={`/bookings/${booking.bookid}`}>
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      View
                    </button>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-between items-center mt-6">
          <div>
            Showing {bookings.length} of {totalBookings} bookings
          </div>
          <div>
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"
            >
              Previous
            </button>
            <span className="mx-2">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bookings;
