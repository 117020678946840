import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import config from '../config';

const Dashboard = () => {
  const [profileData, setProfileData] = useState({
    name: '',
    bookingsCount: 0,
    usersCount: 0,
    successfulPaymentsSum: 0,
    last30Days: 0
  });
  const [showCustomers, setShowCustomers] = useState(false);
  const [showRevenue, setShowRevenue] = useState(false);
  const [show30, setShow30] = useState(false);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await fetch(`${config.apiBaseUrl}/api/admin/profile`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch profile data');
        }

        const data = await response.json();
        setProfileData({
          name: data.name,
          bookingsCount: data.bookingsCount,
          usersCount: data.usersCount,
          successfulPaymentsSum: data.successfulPaymentsSum,
          last30Days: data.last30Days
        });
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    };

    fetchProfileData();
  }, []);

  return (
    <div className="flex min-h-screen bg-gray-800 text-white">
      <Sidebar />
      <div className="flex-1 p-6">
        <div className="flex justify-end mb-4">
          <div className="bg-gray-700 px-4 py-2 rounded">{profileData.name}</div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="bg-white p-4 m-2 rounded-md flex h-48 flex-col items-center justify-center">
            <div className="text-3xl text-blue-900 font-bold m-2 mb-5">Booking</div>
            <div className="text-3xl font-bold text-gray-900 m-2">{profileData.bookingsCount}</div>
          </div>
          <div className="bg-white p-4 m-2 rounded-md flex h-48 flex-col items-center justify-center">
            <div className="text-3xl text-blue-900 font-bold m-2 mb-5">Customers</div>
            <div className="flex items-center gap-2">
              <div className="text-3xl font-bold text-gray-900 m-2">
                {showCustomers ? profileData.usersCount : '*****'}
              </div>
              <button 
                onClick={() => setShowCustomers(!showCustomers)}
                className="text-gray-600 hover:text-gray-900"
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  strokeWidth={1.5} 
                  stroke="currentColor" 
                  className="w-6 h-6"
                >
                  {showCustomers ? (
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                  ) : (
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                  )}
                </svg>
              </button>
            </div>
          </div>
          <div className="bg-white p-4 m-2 rounded-md flex h-48 flex-col items-center justify-center">
            <div className="text-3xl text-blue-900 font-bold m-2 mb-5">Total Revenue</div>
            <div className="flex items-center gap-2">
              <div className="text-3xl font-bold text-gray-900 m-2">
                {showRevenue ? profileData.successfulPaymentsSum : '*****'}
              </div>
              <button 
                onClick={() => setShowRevenue(!showRevenue)}
                className="text-gray-600 hover:text-gray-900"
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  strokeWidth={1.5} 
                  stroke="currentColor" 
                  className="w-6 h-6"
                >
                  {showRevenue ? (
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                  ) : (
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                  )}
                </svg>
              </button>
            </div>
          </div>

          <div className="bg-white p-4 m-2 rounded-md flex h-48 flex-col items-center justify-center">
            <div className="text-3xl text-blue-900 font-bold m-2 mb-5">Current Month</div>
            <div className="flex items-center gap-2">
              <div className="text-3xl font-bold text-gray-900 m-2">
                {show30 ? profileData.last30Days : '*****'}
              </div>
              <button 
                onClick={() => setShow30(!show30)}
                className="text-gray-600 hover:text-gray-900"
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  strokeWidth={1.5} 
                  stroke="currentColor" 
                  className="w-6 h-6"
                >
                  {show30 ? (
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                  ) : (
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                  )}
                </svg>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Dashboard;