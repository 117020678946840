import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';

const BookingDetails = () => {
  const { bookid } = useParams();
  const navigate = useNavigate();
  const [bookingDetails, setBookingDetails] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedDetails, setEditedDetails] = useState(null);
  // const [reviewStatus, setReviewStatus] = useState(null);
  const [reviewResult, setReviewResult] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [bookingResponse, reviewResultResponse] = await Promise.all([
          axios.get(`${config.apiBaseUrl}/api/book/bookings/${bookid}`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }
          })
        ]);
        
        setBookingDetails(bookingResponse.data);
        setEditedDetails(bookingResponse.data);
        // setReviewStatus(reviewCheckResponse.data);
        setReviewResult(reviewResultResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [bookid]);

  const handleEdit = () => setIsEditing(true);

  const handleSave = async () => {
    try {
      const response = await axios.put(
        `${config.apiBaseUrl}/api/book/bookings/${bookid}`, 
        {
          customer_name: editedDetails.customer_name,
          phone: editedDetails.phone,
          email: editedDetails.email,
          visatype: editedDetails.visatype,
          plan: editedDetails.plan,
          drive: editedDetails.drive,
          nation: editedDetails.nation,
          occ: editedDetails.occ, 
          anything: editedDetails.anything,
          status: editedDetails.status,
          razid: editedDetails.razid,
          amount: editedDetails.amount
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
          }
        }
      );
   
      if (response.data.success) {
        window.location.reload();
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      alert(error.response?.data?.message || 'Error updating booking details');
    }
   };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this booking?')) {
      try {
        await axios.delete(`${config.apiBaseUrl}/api/book/deletebooking/${bookid}`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }
        });
        navigate('/bookings');
      } catch (error) {
        console.error('Error deleting booking:', error);
      }
    }
  };

  const handleInputChange = (e) => {
    setEditedDetails({
      ...editedDetails,
      [e.target.name]: e.target.value
    });
  };

  const sendFeedbackEmail = async (bookId, orderId, serviceName, toEmail, customerName) => {
    try {
      // Construct the request body with the parameters
      const requestBody = {
        orderId,
        bookId,
        serviceName,
        toEmail,
        customerName
      };
  
      // Make the POST request with axios
      const response = await axios.post(
        `${config.apiBaseUrl}/api/book/sendfeedback/${bookId}`,
        requestBody,
        {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }
        }
      );
  
      // Handle success response
      if (response.status === 200) {
        alert('Feedback email sent successfully!');
      } else {
        alert(`Failed to send feedback email: ${response.statusText}`);
      }
    } catch (error) {
      // Handle errors
      console.error('Error sending feedback email:', error);
      alert(`Error sending feedback email: ${error.response?.data?.error || error.message}`);
    }
  };
  

  const renderField = (label, field) => {
    const selectOptions = {
      status: ['Payment Due', 'processing', 'cancelled', 'Review', 'Completed'],
      visatype: ['Schengen Visa', 'Ireland Visa', 'Canada Visa', 'United Kingdom Visa'],
      occ: ['Salaried', 'Self-Employed', 'Student', 'Retired', 'Unemployed'],
      plan: ['Get Reviewed', 'A-Z prep'],
      nation: ['India','Other']
    };

    return (
      <p className="mb-4">
        <strong className="block text-sm text-gray-400 uppercase">{label}</strong>
        {isEditing ? (
          selectOptions[field] ? (
            <select
              name={field}
              value={editedDetails[field]}
              onChange={handleInputChange}
              className="mt-1 w-full p-2 rounded text-black"
            >
              {selectOptions[field].map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          ) : (
            <textarea
            name={field}
            value={editedDetails[field]}
            onChange={handleInputChange}
            rows={1} // Default size
            className="mt-1 w-full p-2 rounded text-black resize-y"
          />
          )
        ) : (
          bookingDetails[field]
        )}
      </p>
    );
  };

  if (!bookingDetails) return <div>Loading...</div>;

  return (
    <div className="p-6 m-5 min-h-screen bg-gray-800 text-white">
      <div className="flex justify-between items-center mb-6">
        <button onClick={() => navigate('/bookings')} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Back
        </button>
        <div>
        {bookingDetails.status === 'Review' && (
  <button 
    onClick={() => sendFeedbackEmail(
      bookingDetails.bookid,         // Replace with the actual property for bookId
      bookingDetails.bid,        // Replace with the actual property for orderId
     bookingDetails.visatype + ' - ' + bookingDetails.plan,    // Replace with the actual property for serviceName
      bookingDetails.customer_email,        // Replace with the actual property for toEmail
      bookingDetails.customer_name    // Replace with the actual property for customerName
    )} 
    className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded mb-4 mr-4"
  >
    Send Feedback Email
  </button>
)}


          <button onClick={isEditing ? handleSave : handleEdit} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2">
            {isEditing ? 'Save' : 'Edit'}
          </button>
          <button onClick={handleDelete} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
            Delete
          </button>
          
        </div>
      </div>

      


      {reviewResult && (
        <div className="mb-4 p-4 bg-gray-700 rounded">
          <h2 className="text-xl font-bold mb-2">Review Results</h2>
          <p>Rating: {reviewResult.rating}</p>
          <p>Comment: {reviewResult.comment}</p>
        </div>
      )}

      <h1 className="text-3xl font-bold mb-6">Booking Details</h1>
      <div className="bg-gray-700 p-6 rounded-lg shadow-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
          <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Order ID</strong>
              {bookingDetails.bid}
            </p>
            {renderField('Customer Name', 'customer_name')}

            <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Account Name</strong>
              {bookingDetails.account_name}
            </p>

            {renderField('Booking Email ', 'email')}

            <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Account Email</strong>
              {bookingDetails.customer_email}
            </p>

            {renderField('Customer Phone', 'phone')}
            {renderField('Visa Type', 'visatype')}
            {renderField('Plan', 'plan')}
            <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Booking ID</strong>
              {bookingDetails.bookid}
            </p>
            {renderField('Payment ID', 'razid')}
            {renderField('Amount', 'amount')}
          </div>
          <div>
            {renderField('Drive', 'drive')}
            {renderField('Nation', 'nation')}
            {renderField('Occupation', 'occ')}
            {renderField('Additional Info', 'anything')}
            <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Promo</strong>
              {bookingDetails.promo}
            </p>
            {renderField('Status', 'status')}
            <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Appointments</strong>
              {bookingDetails.appointment_count}
            </p>
           
            <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Created On</strong>
              {new Date(bookingDetails.createdon).toLocaleString()}
            </p>
            <p className="mb-4">
              <strong className="block text-sm text-gray-400 uppercase">Updated On</strong>
              {new Date(bookingDetails.updatedon).toLocaleString()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetails;
